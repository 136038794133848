<template>
	<div id="App">
		<test></test>			
		<img src="./images/banner_02.png" style="width: 100%;" />
		<div class="cont"></div>
		<div class="suspension">
			<div class="su_left">
                <div class="c_text">
				<h2>投诉建议</h2>
                <img src="./images/zp_18.png" />
				<div class="su_fiex">
					<div class="c_l">
						<p>请留下您的意见与建议，我们将在3个工作日内给您反馈</p>
					</div>
				</div>	
					<h3>选择业务类型</h3>
                    <div class="checkP">
                        <div class="check" v-for="item in list">
							<p>{{item.contentTitle}}</p><input type="radio" class="layers-item-selector" :value="item.contentTitle" v-model="businessType" />					
						</div>
                      
                       <!-- {{businessType}} -->
                    </div>
                </div>
				<div class="c_text">
                    <h3>您的联系信息</h3>
                    <div class="su_fiex">
						<div class="c_l">
							<p>请填写如下信息，以便于我们核实身份并与您联系</p>
							<ul>
								<li>
									<p>请填写您的姓名<span>*</span></p>
									<input type="text" v-model="name" />
								</li>
								<li>
									<p>请填写您的联系方式<span>*</span></p>
									<input type="number" v-model="contact" />
								</li>
								<li>
									<p>请填写您的身份证号码<span>*</span></p>
									<input type="number" v-model="custId" />
								</li>
								<li>
									<p>请填写机构名称</p>
									<input type="text" v-model="company" />
								</li>
							</ul>
						</div>
					</div>	
				</div>
				<div class="c_text">
				    <h3>投诉建议</h3>
				    <div class="su_fiex">
						<div class="c_l">
							<p>请详细描述您的问题，最多可输入500个字</p>
							<textarea v-model="conmment"></textarea>
						</div>
					</div>	
				</div>
				<button @click="btn()">提交</button>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	name: 'App',
	components: {
		test,
		foot	
	},
  data() {
    return {
      checkboxIdStr: '', //假设这是你从数据库里取出的数据
	  name:'',
	  contact:'',
	  custId:'',
	  company:'',
	  conmment:'',
	  businessType:'',
	  list:[]
	  
    };
  },
  created() {
	  if (location.href.indexOf("#reloaded") == -1) {
	  	  location.href = location.href + "#reloaded";
	  	  location.reload();
	  }
	  var that = this
	  this.$axios.post('/cms/returnProducts/officialWebsite',)
	  .then(function (res) {
	  	console.log(res);
		that.list = res.data.contentList
	  			
	  	
	  	
	  })
  },

  methods: {

	  btn(){
		  var that = this
		  if(!this.businessType){
			 alert('请选择业务类型')
			 return 
		  }
		  if(!this.name){
		  	alert('请输入姓名')
		  	return
		  }
		  if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.contact))){
		  	alert('请输入正确手机号')
		  	return
		  }
		  if(this.custId.length!=18){
		  	alert('请输入正确身份证号')
		  	return
		  }
		  
		 this.$axios.post('/cms/feedBack/officialWebsite',
		 {name:this.name,contact:this.contact,custId:this.custId,company:this.company,conmment:this.conmment,businessType:this.businessType})
		 .then(function (res) {
		 	console.log(res);
			if(res.data=='success'){
				alert('提交成功')
			}
		 	
		 	
		 }) 
	  }
  }
};
</script>

<style>
	button{
		background-color: #ea0029;
		color: #fff;
		border: none;
		padding: 10px 30px;
		border-radius: 3px;
	}
textarea{
	width: 94%;
	height: 200px;
	border-radius: 5px;
	border-color: #ccc;
	outline: none;
	margin-top: 20px;
	padding: 2%;
}
.c_l ul{
	margin-top: 30px;
}
.c_l ul li input{
	border: none;
	border-bottom: 1px solid #ccc;
	padding: 5px;
	width: 94%;
	outline: none;
}
.su_left .c_l ul li>p span{
	color: #F00
}
.su_left .c_l ul li>p{
	margin-bottom: 10px;
	color: #333;
	font-size: 14px;
}
.c_l ul li{
	margin-bottom: 20px;
}
.cont {
  height: 1000px;
}
.su_right h5 {
  display: flex;
  justify-content: center;
  text-align: left;
}
.su_right h5 p {
  flex: 3;
}
.su_right h5 span {
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  text-align: right;
}
.su_right ul {
  margin-top: 20px;
}
.su_right ul li {
  margin: 15px 0;
  font-size: 14px;
  color: #666666;
  text-align: left;
}
.su_right ul li span {
  background-color: #ea0029;
  color: #fff;
  padding: 3px;
  font-size: 10px;
  border-radius: 5px;
  margin-right: 8px;
}
/* .su_left>p{
		font-size: 12px;
		color: #666666;
		text-align: left;
		margin-bottom: 20px;
	} */
.c_text {
  margin-top: 1%;
  margin-left: 2%;
  text-align: left;
}
.c_text p {
  font-size: 14px;
}
.c_text h2 {
  font-weight: 500;
  font-size: 30px;
}
.c_text h3 {
  font-weight: 500;
  font-size: 20px;
}
.c_text img {
  width: 60px;
  margin-bottom: 20px;
}
.suspension {
  position: absolute;
  top: 20%;
  left: 17.5%;
  width: 65%;
  max-width: 65%;
  display: flex;
  justify-content: center;
}
.su_left .c_r {
  flex: 4;
  text-align: right;
}
.su_left .c_r button {
  background-color: #ea0029;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 15px;
}
.su_left .c_r p {
  margin-top: 10px;
  font-size: 12px;
  color: #898383;
}
.su_left .c_l {
  flex: 6;
  text-align: left;
}
.su_left .c_l p {
  font-size: 13px;
  color: #898383;
}
.su_left .c_l h4 {
  font-weight: 500;
  font-size: 18px;
}
.su_left .c_l h1 {
  color: #ea0029;
  margin: 10px 0;
}
.su_left .c_l h4 span {
  background-color: #ea0029;
  font-size: 14px;
  padding: 3px;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}
.su_fiex {
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
}
.su_left {
  box-shadow: 4px 8px 15px #cccccc;
  background-color: #fff;
  flex: 7;
  padding: 3%;
  border-radius: 5px;
}
.layers-item-selector {
  width: 20px;
  height: 20px;
  background: url("~@/images/uncheck.png");
  background-size: 100% 100%;
  border: solid 0px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 0.8rem;
  margin-top: 2%;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  /* -webkit-transition:background-color ease 0.1s;
        transition:background-color ease 0.1s; */
  outline: none;
}
/*直接用图片代替选中的样子，如果不需要，可设置背景色*/
.layers-item-selector:checked {
  background: url("~@/images/checked.png");
  background-size: 100% 100%;
}
.check {
  height: 34px;
  width: 140px;
  box-sizing: border-box;
  flex: 0 0 30%;
  margin-bottom: 3%;
  margin-right: 3%;
  /* text-align: center; */
  border: solid 1px #cccccc;
  border-radius: 3px;
}
.check p {
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 160px;
  display: inline-block;
}
.checkP {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
</style>
